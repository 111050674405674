<template>
  <div class="report-container">
    <Form :model="formData" :label-width="100" class="form">
      <Form-item label="报告类型">
        <Select v-model="formData.type" @on-change="onTypeChange">
          <Option
            :value="type.type"
            v-for="type in types" 
            :key="type.type">{{ type.name }}</Option>
        </Select>
      </Form-item>
      <Form-item label="报告名称" v-if="formData.type && types[formData.type - 1].child.length > 0">
        <Select v-model="formData.bgzl">
          <Option
            :value="sub.id"
            v-for="sub in types[formData.type - 1].child"
            :key="sub.id">{{ sub.name }}</Option>
        </Select>
      </Form-item>
      <Form-item label="文件">
        <Upload action="/api/check/photo/upload"
                name="photo"
                ref="upload"
                :max-size="1024 * 30"
                :data="{check: 1}"
                :format="['png','jpg','jpeg','pdf']"
                :on-format-error="handleFormatError"
                :on-success="handleUploadSuccess"
                :on-exceeded-size="handleMaxSize"
                :on-remove="handleRemove"
                :before-upload="handleBeforeUpload">
          <Button icon="ios-cloud-upload-outline">上传</Button>
        </Upload>
      </Form-item>
      <Form-item label="报告描述">
        <Input v-model="formData.bgms" type="textarea" placeholder="请输入..."></Input>
      </Form-item>
      <Form-item label="咨询内容">
        <Input v-model="formData.zxnr" type="textarea" placeholder="请输入..."></Input>
      </Form-item>
      <Form-item>
        <Button type="primary" @click="handleSubmit">提交报告</Button>
        <Button style="margin-left: 10px" @click="$router.back()">取消</Button>
      </Form-item>
    </Form>
  </div>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			formData: {
				type: '',
				bgzl: '',
				check_date: '',
				bgms: '',
				zxnr: '',
				photo_id: '',
			},
			types: [],
			uploadList: [],
		};
	},
	mounted() {
		this.getReportType();
		this.uploadList = this.$refs.upload.fileList;
	},
	methods: {
		getReportType() {
			MemberService.getReportType().then((data) => {
				this.types = data;
				if (this.$route.params.id) {
					MemberService.reportDetail({ id: this.$route.params.id }).then((data) => {
						this.formData = data;
					});
				}
			});
		},
		onTypeChange() {
			this.formData.bgzl = '';
		},
		handleSubmit() {
			if (this.$route.params.id) this.formData.id = this.$route.params.id;
			this.formData.member_id = this.$route.params.member_id;
			MemberService.addReport(this.formData)
				.then(() => {
					this.$Message.success('提交成功');
					this.$router.back();
				})
				.catch(() => {
					this.$Message.warning('提交失败，请重试');
				});
		},
		handleUploadSuccess(response) {
			if (response.c === 0) {
				this.formData.photo_id = response.data.id;
				this.formData.check_date = response.data.check_date;
			} else {
				const uploadRef = this.$refs.upload;
				this.$Message.error(response.m);
				uploadRef.handleRemove(uploadRef.fileList[0]);
			}
		},
		handleMaxSize(file) {
			this.$Message.warning('文件 ' + file.name + ' 太大，不能超过 30 M。');
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 png、jpg、jpeg 或 pdf 格式的文件。',
			);
		},
		handleBeforeUpload() {
			const check = this.uploadList.length < 1;
			if (!check) {
				this.$Message.warning('最多只能上传 1 个文件。');
			}
			return check;
		},
		handleRemove() {
			this.formData.photo_id = '';
		},
	},
};
</script>

<style lang="less" scoped>
.report-container {
  background-color: #fff;
  padding: 15px;
  .form {
    width: 600px;
    margin: 0 auto;
  }
}
</style>
